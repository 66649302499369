import PropTypes from "prop-types"
import * as THREE from "three"
import React, { Suspense, useState, useRef, useCallback } from "react"
import "./header.css"
import { Canvas } from "react-three-fiber"

import Logo from "./Text/logo"
import Effect from "./Text/effect"

const FallbackLogo = () => (
  <div className="box">
    <div className="inner-box">
      <span className="title">[DNX]</span>
      <span className="subtitle">Web &amp; Mobile Developer</span>
    </div>
  </div>
)

const Header = ({ siteTitle }) => {
  const [hovered, hover] = useState(false)
  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(
    ({ clientX: x, clientY: y }) =>
      (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]),
    []
  )
  return (
    <header className="container">
      <Canvas
        camera={{ fov: 100, position: [0, 0, 30] }}
        onMouseMove={onMouseMove}
        onMouseUp={() => hover(false)}
        onMouseDown={() => hover(true)}
        onPointerUp={() => hover(false)}
        onPointerDown={() => hover(true)}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.Uncharted2ToneMapping
          gl.setClearColor(new THREE.Color("#020207"))
        }}
      >
        <Suspense fallback={FallbackLogo}>
          <ambientLight intensity={0.5}></ambientLight>
          <Logo
            hAlign="center"
            textSize={2}
            position={[0, 0, 0]}
            children="DNX"
            mouse={mouse}
            hover={hover}
          />
          <Logo
            hAlign="center"
            color="#444DAE"
            textSize={1}
            position={[0, -50, -60]}
            children="???"
            mouse={mouse}
            hover={hover}
          />
          <Effect hover={hovered}></Effect>
        </Suspense>
      </Canvas>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
