import * as THREE from "three"
import React, { useMemo } from "react"
import { useUpdate } from "react-three-fiber"

import norwesterJSON from "./norwester.json"

export default function ({
  children,
  vAlign = "center",
  hAlign = "center",
  textSize = 1,
  color = "#666DAE",
  mouse,
  hover,
  ...props
}) {
  const loader = new THREE.FontLoader()
  const font = loader.parse(norwesterJSON)
  const config = useMemo(
    () => ({
      font,
      size: 92,
      height: 12,
      curveSegments: 24,
      bevelEnabled: false,
    }),
    [font]
  )
  const mesh = useUpdate(
    self => {
      const size = new THREE.Vector3()
      self.geometry.computeBoundingBox()
      self.geometry.boundingBox.getSize(size)
      self.position.x =
        hAlign === "center" ? -size.x / 2 : hAlign === "right" ? 0 : -size.x
      self.position.y =
        vAlign === "center" ? -size.y / 2 : vAlign === "top" ? 0 : -size.y
    },
    [children]
  )
  // Rotate mesh every frame, this is outside of React without overhead
  // const { size, viewport } = useThree()
  // const aspect = size.width / viewport.width
  // useFrame(() => {
  //   mesh.current.rotation.x = lerp(
  //     mesh.current.rotation.x,
  //     0 + mouse.current[1] / aspect / 300,
  //     0.1
  //   )
  //   mesh.current.rotation.y = lerp(
  //     mesh.current.rotation.y,
  //     0 + mouse.current[0] / aspect / 300,
  //     0.1
  //   )
  // })

  return (
    <group
      {...props}
      scale={[0.1 * textSize, 0.1 * textSize, 0.1]}
      onPointerOver={() => hover(true)}
      onPointerOut={() => hover(false)}
    >
      >
      <mesh ref={mesh}>
        <ambientLight intensity={0.9} />
        <textGeometry attach="geometry" args={[children, config]} />
        <meshBasicMaterial attach="material" color={color} />
      </mesh>
    </group>
  )
}
